import WidgetHeader from 'src/components/FullPatientData/PatientWidgetBody/Widget/WidgetHeader/WidgetHeader';
import { DELETE_PROFILE_WIDGET } from 'src/store/actions/types';
import { mapActions } from 'vuex';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    dragStartHandler: {
      type: Function,
      required: true,
    },
    type: {
      type: Number,
      required: false,
    },
    /**
     * defaults to false, meaning the card-body will be automatically
     * provided by the widget. if true, the slot="body" must be a "b-card-body" tag.
     *
     * this is useful if you need at certain custom css like
     * "max-height" and "overflow-y: auto;" to the body of the widget/card.
     *
     * @see https://bootstrap-vue.org/docs/components/card#comp-ref-b-card-body
     */
    noBody: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showHideModal: false,
    };
  },
  components: {
    WidgetHeader,
  },
  methods: {
    ...mapActions([DELETE_PROFILE_WIDGET]),
    /**
     * handles the hide widget event from the header
     */
    hideHandler() {
      this.$refs['hide-modal'].show();
    },
    /**
     * handles the OK action of the hide modal
     */
    hideModalOkHandler() {
      this[DELETE_PROFILE_WIDGET]({ type: this.type });
    },
  },
};
