import { library } from '@fortawesome/fontawesome-svg-core';
import { faGripVertical } from '@fortawesome/pro-light-svg-icons';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons';

library.add(faGripVertical);
library.add(faEyeSlash);

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    dragStartHandler: {
      type: Function,
      required: true,
    },
    hideHandler: {
      type: Function,
      required: true,
    },
  },
  components: {
  },
};
